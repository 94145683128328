import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Magic Overview`}</h2>
    <h3>{`SPECIAL ABILITIES`}</h3>
    <h6>{`Spell-Like Abilities`}</h6>
    <p>{`Usually, a spell-like ability works just like
the spell of that name. A few spell-like abilities are unique; these
are explained in the text where they are described.`}</p>
    <p>{`A spell-like ability has no verbal, somatic, or material
component, nor does it require a focus or have an XP cost. The user
activates it mentally. Armor never affects a spell-like ability`}{`’`}{`s use,
even if the ability resembles an arcane spell with a somatic component.
A spell-like ability has a casting time of 1 standard action unless
noted otherwise in the ability or spell description. In all other ways,
a spell-like ability functions just like a spell.`}</p>
    <p>{`Spell-like abilities are subject to spell resistance and to
being dispelled by dispel magic. They do not function in areas where
magic is suppressed or negated. Spell-like abilities cannot be used to
counterspell, nor can they be counterspelled.`}</p>
    <p>{`Some creatures are actually sorcerers of a sort. They cast
arcane spells as sorcerers do, using components when required. In fact,
an individual creature could have some spell-like abilities and also
cast other spells as a sorcerer.`}</p>
    <h6>{`Supernatural Abilities`}</h6>
    <p>{`These abilities cannot be disrupted in combat,
as spells can, and they generally do not provoke attacks of
opportunity. Supernatural abilities are not subject to spell
resistance, counterspells, or to being dispelled by dispel magic, and
do not function in areas where magic is suppressed or negated.`}</p>
    <h6>{`Extraordinary Abilities`}</h6>
    <p>{`These abilities cannot be disrupted in combat,
as spells can, and they generally do not provoke attacks of
opportunity. Effects or areas that negate or disrupt magic have no
effect on extraordinary abilities. They are not subject to dispelling,
and they function normally in an antimagic field. Indeed, extraordinary
abilities do not qualify as magical, though they may break the laws of
physics.`}</p>
    <h6>{`Natural Abilities`}</h6>
    <p>{`This category includes abilities a creature
has because of its physical nature. Natural abilities are those not
otherwise designated as extraordinary, supernatural, or spell-like.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      